export default {
  en: {
    auth: {
      signInPageTitle: "Sign in to your account",
      form: {
        username: "Email",
        password: "Password",
      },
      action: {
        signIn: "Sign in",
      },
    },
    notifications: {
      mutations: {
        success: "Successfully saved!",
        error: "Error occurred",
        delivering: "The devices will receive the updated config as soon as they are online.",
        contactSupport: "Please contact CARU support",
        jsonParse: "Server could not parse JSON. Please check your data",
        templateStored: "Configuration template was stored successfully.",
        templateStoredFailure: "Error occurred! Please try again or contact the CARU support Team.",
        tenantCreated: "Tenant { tenant } successfully created.",
        userInvited: "User was invited!",
        userInvitedDescription: "User was successfully invited and email with temporary password was send",
        userInvitedError: "Something went wrong please try again later or contact CARU support",
      },
      auth: {
        error: "Error occurred",
        invalidCredentials: "Incorrect username or password.",
      },
    },
    device: {
      label: "device | devices",
      selection: "{ selection } of { length } devices selected",
      listPageTitle: "Manage Users",
      detailsPageTitle: "Account",
      softwareSupport:
        "Your device is equipped with an outdated software version. Please switch on the device so that the latest software can be installed automatically. As soon as the update has been carried out, the device can be configured in the platform as usual. If you have any further questions, please contact our support team:",
      header: {
        deviceConnectivityStatus: "Device Connectivity Status",
        connectivityStatus: "Connectivity Status",
        errorStatus: "Error Status",
        serialNumber: "Serial Number",
        firmware: "Firmware",
        id: "Device ID",
        tenant: "Tenant Name",
        version: "Version",
        notifications: "Notifications",
        copyConfiguration: "Copy Configuration",
        setGroup: "Set devices group",
        tabNotification: "Plugout Notifications",
        unknown: "UNKNOWN",
        bracelets: "Bracelets",
        setOwner: "Set device owner",
        dailyCheck: "Daily Check In",
        co2Toggle: "Co2 Indication",
      },
      form: {
        permissions: "Permissions",
        roles: {
          none: "No Access",
          viewer: "Viewer",
          admin: "Admin",
        },
        notifications: "Notifications",
        alarmDestinations: "Alarm Destinations",
        bracelets: "Bracelets",
        sourceDevice: "Source Device",
        ownerName: "Owner Name",
        selectDevice: "- Select Device -",
      },
      action: {
        diagnose: "Diagnose",
        group: "Group",
        owner: "Owner",
        alarmConfig: "Alarm Config",
        notifications: "Notifications",
        reset: "Reset",
        reboot: "Reboot",
        shutdown: "Shutdown",
        deactivate: "Deactivate",
        selfDiagnosis: "Self Diagnosis",
        copyConfig: "Copy Config",
        advancedNetworkScan: "Advanced Network Scan",
        basicNetworkScan: "Basic Network Scan",
        changeOwner: "Change Owner",
        applyConfigTemplate: "Apply Config Template",
        cancel: "Cancel",
        save: "Save",
        addBracelets: "Add Bracelets",
      },
      general: {
        firmware: "Firmware Version",
        modem: "Modem Status",
        wifi: "WiFi Status",
        battery: "Battery Status",
        dailyCheckIn: "Daily Check In",
      },
      network: {
        pageTitle: "Network Scans",
        header: {
          operator: "Operator",
          signalQuality: "Signal Quality",
          networkQuality: "Network Quality",
          qualityScore: "Quality Score",
          selectNetwork: "Select Network",
        },
        signal: {
          unusable: "Unusable",
          bad: "Bad",
          ok: "Ok",
          good: "Good",
        },
        performance: {
          uploadSpeed: "Upload Speed: { uploadSpeed } kbps",
          downloadSpeed: "Download Speed: { downloadSpeed } kbps",
          jitter: "Jitter: { jitter } ms",
          roundTripTime: "Round Trip Time: { roundTripTime } ms",
          packetLoss: "Packet Loss: { packetLoss }",
        },
        metrics: {
          uploadSpeed: "Upload Speed: { uploadSpeed } kbps",
          downloadSpeed: "Download Speed: { downloadSpeed } kbps",
          jitter: "Jitter: { jitter } ms",
          roundTripTime: "Round Trip Time: { roundTripTime } ms",
          packetLoss: "Packet Loss: { packetLoss }",
        },
        scan: {
          startedLabel: "{ type } Network Scan started at { started }",
          inProgress: "(in progress)",
          quality: "Quality: { signalLevel }",
          rsrp: "RSRP: { rsrp }",
          rsrq: "RSRQ: { rsrq }",
          rssi: "RSSI: { rssi }",
        },
      },
      events: {
        pageTitle: "Device Events",
        header: {
          type: "Event",
          timestamp: "Timestamp",
        },
        empty: "No events available",
      },
      feature: {
        header: {
          featureFlags: "Feature Flags",
          developmentSettings: "Development Settings",
        },
        audio: "Audio: Sound Volume",
        cortex: "Cortex: Log Level",
      },
      settings: {
        general: "General",
        alertDestinations: "Alert Destinations",
        bracelets: "Bracelets",
        connectivity: "Connectivity",
        events: "Events",
        features: "Features",
      },
      tooltip: {
        braceletPresenceStatus: "Bracelet presence status",
        dailyCheckIn: "Daily check in status",
        braceletBatteryStatus: "Bracelet battery status",
        presenceStatus: "Presence status",
        wifiStatus: "Wifi status",
        powerSupplyStatus: "Power supply status",
      },
      placeholder: "Search device IDs",
      loading: "Loading devices...",
      errorStatus: {
        ok: "OK",
        error: "Error",
        unknown: "Unknown",
        off: "Off",
        emptyBattery: "Battery Empty",
        missingDevice: "Offline",
        lowPowerMode: "Power-Savings-Mode",
      },
    },
    notification: {
      deviceReset: "Device reset started...",
      deviceDiagnosis: "Device diagnosis started...",
      deviceRefresh: "{serialNumber} updated",
      deviceAdvancedScan: "Advanced Network Scan ({scanId}) started for device: {deviceId}",
      deviceBasicScan: "Basic Network Scan ({scanId}) started for device: {deviceId}",
      header: {
        plugoutNotifications: "Plugout Notifications",
      },
      form: {
        sms: "SMS",
        email: "Email",
      },
      action: {
        addNotificationReceiver: "Add Notification Receiver",
      },
    },
    user: {
      listPageTitle: "Manage Users",
      detailsPageTitle: "Account",
      header: {
        name: "Name",
        tenant: "Tenant",
        role: "Role",
        edit: "Edit",
      },
      form: {
        permissions: "Permissions",
        roles: {
          none: "No Access",
          viewer: "Viewer",
          admin: "Admin",
        },
      },
      placeholder: "Search devices",
    },
    alert: {
      applyNotificationTitle: "Apply notification configuration?",
      applyAlarmTitle: "Apply alarm configuration?",
      applyGroupTitle: "Set group ?",
      applyOwnerTitle: "Set owner ?",
      resetDeviceTitle: "Reset Device",
      applyBatchResetTitle: "Device Reset",
      applyBatchRebootTitle: "Device Reboot",
      applyBatchShutdownTitle: "Device Shutdown",
      applyBatchDeactivateTitle: "Device Deactivate",
      applyChangesTitle: "Confirm changes",
      leavePage: "Leave Page",
      applyBatchResetDescription: "Are you sure you want to reset ${length} devices? This action cannot be undone.",
      applyBatchRebootDescription: "Are you sure you want to reboot ${length} devices?",
      applyBatchShutdownDescription: "Are you sure you want to shutdown ${length} devices?",
      applyBatchDeactivateDescription: "Are you sure you want to deactivate ${length} devices?",
      applyNotificationDescription:
        "Are you sure you want to apply this notification configuration to all selected devices? All existing configurations will be overwritten. This action cannot be undone.",
      applyAlarmDescription:
        "Are you sure you want to apply this alarm configuration to all selected devices? All existing configurations will be overwritten. This action cannot be undone.",
      applyGroupDescription:
        "Are you sure you want to set this group name to all selected devices? All existing groups will be overwritten. This action cannot be undone.",
      applyOwnerDescription:
        "Are you sure you want to set this owner name to all selected devices? All existing owners will be overwritten. This action cannot be undone.",
      resetDeviceDescription: "Are you sure you want to reset this device? This action cannot be undone.",
      applyChangesDescription: "Are you sure you want to apply the changes?",
      leavePageDescription: "You have unsaved changes. Are you sure you want to leave without saving?",
      deleteTemplateTitle: "Delete Template?",
      deleteTemplateDescription: "Are you sure you want to delete the template?",
    },
    configuration: {
      header: {
        config: "Configuration",
      },
      expertMode: "Expert Mode",
      editJson: "Edit Json",
    },
    safety: {
      header: {
        safety: "Safety",
      },
    },
    health: {
      header: {
        health: "Health",
      },
    },
    system: {
      header: {
        system: "System",
        volumeSettings: "Device Volume",
      },
      volume: {
        quiet: "quiet",
        default: "default",
        loud: "loud",
      },
    },
    scaip: {
      form: {
        header: "Set Controller ID",
        controllerId: "Controller ID",
        callerId: "Caller ID",
        errorControllerId: "Controller ID can only contain upper case letters and numbers",
      },
    },
    userManagement: {
      listPageTitle: "User Management",
      tab: {
        user: "Users",
        tenants: "Tenants",
        inviteUser: "Invite User",
        editUser: "Edit User",
        createTenant: "Create Tenant",
        updateTenant: "Update Tenant",
      },
      userTable: {
        name: "Name",
        email: "Email",
        phone: "Phone Number",
        tenants: "Tenants",
        role: "Role",
        state: "State",
        pwd: "Password",
        pwdRepeat: "Repeat Password",
      },
      tenantTable: {
        name: "Name",
        slug: "Slug",
        devices: "Devices",
        serialNumber: "Serial Number",
        showLess: "Show less devices",
        showAll: "Show all devices",
        deviceTenants: "Devices of Tenant",
        newDevices: "New Devices",
      },
      action: {
        inviteUser: "Invite User",
        inviteUserDescription:
          "Are you sure you want to invite the User? An Email will be send with the temporary password",
        deleteUser: "Delete User",
        deleteUserDescription: "Are you sure you want to delete following user: {email}?",
        updateUser: "Change User",
        updateUserDescription: "Are you sure you want to update the user?",
        createTenant: "Create Tenant",
        createTenantDescription: "Are you sure you want to create the tenant?",
        updateTenant: "Update Tenant",
        updateTenantDescription: "Are you sure you want to save the changes?",
      },
    },

    configTemplates: {
      listPageTitle: "Config Templates",
      listPageTitleEdit: "Config Template",
      header: {
        id: "Id",
        name: "Name",
        placeholder: "Placeholder",
        lastUpdate: "Last Updated",
        lastUpdateBy: "Last Change",
        owner: "Owner",
        applier: "Applier",
        edit: "Edit",
        template: "Template",
        preview: "Preview",
        example: "Example Value",
      },
      templateDescription: "Placeholders start and end with '$'.",
    },
    alarm: {
      listPageTitle: "Alarm Center | Alarm Centers",
      header: {
        name: "Name",
        tenant: "Tenant",
        alertTriggers: "Alert Triggers",
        alarmDestinations: "Alarm Destinations",
      },
      destination: {
        pstn: "PSTN",
        sip: "SIP",
        arc: "ARC",
        isArc: "Is ARC",
        arcName: " ARC-Name (SCAIP-Server-Address)",
      },
      form: {
        name: "Name",
        host: "Host",
        port: "Port",
        username: "Username",
        password: "Password",
        tenant: "Tenant",
        country: {
          caru: "CARU",
          caritasSoest: "Caritas Soest",
        },
        selectArc: "-- Select ARC --",
        triggerByVoice: "Trigger by Voice",
        triggerByTouch: "Trigger by Touch",
        triggerByBracelet: "Trigger by Bracelet (Fall Detection)",
        triggerByVoiceOnBattery: "Enabled in battery mode",
        triggerByVoiceOnBatteryActive: "The battery lasts about 2h",
        triggerByVoiceOnBatteryInactive: "The battery lasts about 24h",
      },
      action: {
        addDestination: "Add Destination",
      },
      tooltip: {
        triggerByVoiceOnBatteryActive:
          "Voice emergency call initiation is disabled during a power interruption to minimize battery drain and maximize ride through time. During a power failure, no emergency call can be made by voice. Emergency calls can still be triggered via the radio transmitter and on CARU care.",
        triggerByVoiceOnBatteryInactive:
          "The emergency call can also be triggered by voice during a power failure. Due to the power consumption of the speech recognition, the time to bridge the interruption is limited.",
      },
    },
    dailyCheck: {
      status: {
        ok: "OK",
        disabled: "Disabled",
        missing: "Not confirmed",
        suspended: "Suspended",
        unknown: "Unknown",
      },
      form: {
        active: "Active",
        inactive: "Inactive",
        suspended: "Suspended",
        suspendedDate: "Suspended End Date",
        checkTime: "Check time",
        gracePeriod: "Grace period (min)",
        remindersDescription: "Reminders are played after {firstReminder} and {secondReminder} minutes",
      },
    },
    hydrationReminder: {
      titleDescription: "Reminders are played from {firstSlot} to {secondSlot} and {thirdSlot} to {fourthSlot}",
      reminderInterval: "Reminder Interval",
      intervalSelection: "Reminder Interval",
      intervalDescription: "Sounds are played around the selected time.",
      intervalPreviewDescription: "Reminders are played around:",
      previewSound: "Preview Sound",
      previewSoundDescription: "Sound is played in the browser",
      play: "Play",
      updateVersion:
        "This functionality is available from version 2023.3.3 ongoing. Please contact our support under <a href='mailto:email@example.com'>dennis.cserhati@caruhome.com</a> or 🇨🇭 +41 44 512 13 77 🇩🇪 +49 451 81189063 to update your device.",
    },
    event: {
      header: {
        events: "Latest device notifications",
      },
    },
    stateSummary: {
      label: {
        deviceReportedStates: "Device Reported States",
        cloudReportedStates: "Cloud Reported States",
        powerSupplyStates: "Power Supply",
        braceletPresenceStates: "Bracelet States",
        braceletBatteryStates: "Bracelet Batteries",
        deviceReportedState: "Device Reported State",
        cloudReportedState: "Cloud Reported State",
        powerSupplyState: "Power Supply",
        braceletPresenceState: "Bracelet State",
        braceletBatteryState: "Bracelet Battery",
        dailyCheckIn: "Daily Check-In",
        missingDeviceDueBattery: "Missing Devices due Battery",
        missingDeviceDueUnknown: "Missing Devices Unknown",
        interruptedPower: "Interrupted Power Supply",
        missingBracelets: "Missing Bracelets",
        braceletBattery: "Critical Bracelet Battery",
        wifiConnection: "Wifi Connection",
      },
      deviceReportedPresence: {
        online: "Online",
        offlineBatteryEmpty: "Offline Empty Battery",
        offlineLowPowerMode: "Offline Low Power",
        offlineTurnedOff: "Offline Turned Off",
        unknown: "Unknown",
      },
      cloudReportedPresence: {
        online: "Online",
        offlineUnknown: "Offline Unknown",
        offlineDeviceInitiated: "Offline Device Initiated",
        offlineCloudInitiated: "Offline Cloud Initiated",
        unknown: "Unknown",
      },
      powerSupply: {
        connected: "Connected",
        disconnected: "Disconnected",
        unknown: "Unknown",
      },
      braceletPresence: {
        inRange: "In range",
        outOfRange: "Out of range",
        unknown: "Unknown",
      },
      braceletBattery: {
        ok: "Ok",
        critical: "Critical",
        unknown: "Unknown",
      },
      dailyCheckIn: {
        active: "Active",
        deactivated: "Deactivated",
        pausedByDevice: "Paused by device",
        pausedByPlatform: "Paused by platform",
        missing: "Missing",
        unknown: "Unknown",
      },
      notifications: {
        maxAmountTitle: "Maximum Filter",
        maxAmount: "Only three filters can be applied at the same time. Please remove one to add another one",
      },
    },
    error: {
      filter: {
        label: {
          deviceReportedStates: "Device Reported States",
          cloudReportedStates: "Cloud Reported States",
          powerSupply: "Power Supply",
          braceletStates: "Bracelet States",
          braceletBatteryState: "Bracelet Battery",
          dailyCheckIn: "Daily Check-In",
          missingDeviceDueBattery: "Missing Devices due Battery",
          missingDeviceDueUnknown: "Missing Devices Unknown",
          interruptedPower: "Interrupted Power Supply",
          missingBracelets: "Missing Bracelets",
          braceletBattery: "Critical Bracelet Battery",
        },
        state: {
          online: "Online",
          offline: "Offline",
          offlineLowPower: "Offline Low Power",
          offlineTurnedOff: "Offline Turned Off",
          offlineDeviceInitiated: "Offline Device Initiated",
          offlineCloudInitiated: "Offline Cloud Initiated",
          connected: "Connected",
          disconnected: "Disconnected",
          inRange: "In range",
          outOfRange: "Out of range",
          ok: "Ok",
          critical: "Critical",
          deactivated: "Deactivated",
          active: "Active",
          pausedByCloud: "Paused by Cloud",
          pausedByUser: "Paused by User",
          missing: "Missing",
        },
        actionLabel: {
          missingDeviceDueBattery: "Filter for Missing Devices due Battery",
          missingDeviceDueUnknown: "Filter for Missing Devices",
          interruptedPower: "Filter for Interrupted Power Supply",
          missingBracelets: "Filter for Missing Bracelets",
          braceletBattery: "Filter for Critical Bracelet Battery ",
        },
      },
      unknown: "Unknown error",
      notFound: "Not Found",
      destinationEmpty: "Destination cannot be empty.",
      receiverEmpty: "Receiver cannot be empty.",
      braceletEmpty: "Bracelet ID cannot be empty.",
      braceletFormat: "Bracelet ID is not valid: Must be 12 digits number. Example: 006569866354",
      braceletDuplicate: "Bracelets have to be unique. Some values are duplicates.",
      sipAddressFormat: "SIP address not valid. Expected format: user@host.com",
      phoneNumberFormat: "Phone number not valid. Expected format: 004178021212",
      emailFormat: "Email address not valid. Expected format: user@host.com",
    },
    search: {
      placeholder: "Search devices",
    },
    profileMenu: {
      signOut: "Sign out",
    },
    filters: {
      all: "All",
      operationStatus: "Operation Status",
      allOk: "All OK",
      online: "Online",
      offline: "Offline",
      deviceNotifications: "Device Notifications",
      batteryPowered: "Battery mode",
      batteryEmpty: "Battery empty",
      braceletNotifications: "Bracelet Notifications",
      batteryLow: "Battery low",
      braceletOutOfRange: "Not reachable",
      off: "Switched off",
      dailyCheckIn: "Daily Check In",
      dailyCheckInActive: "Active",
      dailyCheckInMissing: "Daily Check In missing",
      dailyCheckInPaused: "Paused",
      dailyCheckInByUser: "By User",
      dailyCheckInPlatform: "By Platform",
      dailyCheckInDeactivated: "Inactive",
      healthNotifications: "Health Notifications",
      healthNotificationsCo2: "CO2 Indikator",
      healthNotificationsDrink: "Drink reminder",
      bracelet: "Bracelet",
      error: "Errors",
    },
    alarms: {
      sidebar: {
        critical:
          "The ‘critical’ severity level indicates that a service- affecting condition has occurred and an immediate corrective action is required. Such a severity can be reported, for example, when a resource becomes totally out of service and its capability must be restored.",
        major:
          "The ‘major’ severity level indicates that a service- affecting condition has developed and an urgent corrective action is required. Such a severity can be reported, for example, when there is a severe degradation in the capability of the resource and its full capability must be restored.",
        minor:
          "The ‘minor’ severity level indicates the existence of a non-service-affecting fault condition and that corrective action should be taken in order to prevent a more serious (for example, service-affecting) fault. Such a severity can be reported, for example, when the detected alarm condition is not currently degrading the capacity of the resource.",
        warning:
          "The ‘warning’ severity level indicates the detection of a potential or impending service-affecting fault, before any significant effects have been felt. Action should be taken to further diagnose (if necessary) and correct the problem in order to prevent it from becoming a more serious service-affecting fault.",
      },
      power_supply: "Power supply",
      battery_fault: "Battery health",
      wifi_link: "Wifi link",
      wifi_connection_quality: "Wifi connection quality",
      modem_link: "Modem link",
      modem_connection_quality: "Modem connection quality",
      bracelet_presence: "Bracelet presence",
      bracelet_battery: "Bracelet battery",
      cloud_connection: "Cloud connection",
      mqtt_connection: "MQTT connection",
      network_cloud_reported: "Cloud Reported Network Alarm",
      network_arc_reported: "Arc Reported Network Alarm",
      unknown: "Unknown",
      normal: "Normal",
      warning: "Warning",
      minor: "Minor",
      major: "Major",
      critical: "Critical",
      wifiNotConnected: "Wifi - Not connected",
      wifiConnected: "Connected",
    },
    navigationBar: {
      userManagement: "User Management",
      configTemplates: "Configuration Templates",
      alarmCenters: "Alarm Centers",
      users: "Users",
      docs: "Handbook",
    },
    common: {
      label: {
        useSetting: "Use setting",
        since: "since",
      },
      action: {
        cancel: "Cancel",
        save: "Save",
        edit: "Edit",
        apply: "Apply",
        leave: "Leave",
        delete: "Delete",
      },
    },
    "crn::cortex.lifecycle.battery::event-type:critical": "Device Battery Critical",
    "crn::cortex.lifecycle.battery::event-type:fault": "Device Battery Error",
    "crn::cortex.lifecycle.bracelet::event-type:battery_critical": "Bracelet Battery Critical",
    "crn::cortex.lifecycle.bracelet::event-type:heartbeat": "Bracelet Heartbeat",
    "crn::cortex.lifecycle.bracelet::event-type:out_of_range": "Bracelet Out-Of-Range",
    "crn::cortex.lifecycle.system::event-type:booted": "Device-Software Started",
    "crn::cortex.lifecycle.system::event-type:bottom_button": "Device Turned Off",
    "crn::cortex.lifecycle.system::event-type:shutdown": "Device Shut Down",
    "crn::cortex.lifecycle.system::event-type:reboot": "Device Reboot",
    "crn::cortex.lifecycle.wall_adapter::event-type:connected": "Powerline Connected",
    "crn::cortex.lifecycle.wall_adapter::event-type:disconnected": "Powerline Disconnected",
    "crn::cortex.lifecycle::event-type:arc_call": "Alarm Call Initiated",
    "crn::cortex.lifecycle.daily_check_in::event-type:activated": "Daily Check In Activated",
    "crn::cortex.lifecycle.daily_check_in::event-type:deactivated": "Daily Check-In Deactivated",
    "crn::cortex.lifecycle.daily_check_in::event-type:requested": "Daily Check-In Requested",
    "crn::cortex.lifecycle.daily_check_in::event-type:reminded": "Daily Check-In Reminded",
    "crn::cortex.lifecycle.daily_check_in::event-type:done": "Daily Check-In Done",
    "crn::cortex.lifecycle.daily_check_in::event-type:missing": "Daily Check-In Missing",
    "crn::cortex.lifecycle.daily_check_in::event-type:suspended_by_platform":
      "Daily Check-In Suspended by Platform User",
    "crn::cortex.lifecycle.daily_check_in::event-type:resumed_by_platform": "Daily Check-In Resumed by Platform User",
    "crn::cortex.lifecycle.daily_check_in::event-type:suspended_by_device": "Daily Check-In Suspended by Device User",
    "crn::cortex.lifecycle.daily_check_in::event-type:resumed_by_device": "Daily Check-In Resumed by Device User",
  },
  de: {
    event: {
      header: {
        events: "Zuletzt empfangene Geräte-Meldungen",
      },
    },
    auth: {
      signInPageTitle: "Melden Sie sich bei Ihrem Konto an",
      form: {
        username: "E-Mail",
        password: "Passwort",
      },
      action: {
        signIn: "Einloggen",
      },
    },
    notifications: {
      mutations: {
        success: "Änderungen gespeichert!",
        error: "Fehler aufgetreten",
        delivering: "Das Gerät erhält die Änderungen in Kürze.",
        contactSupport: "Bitte kontaktieren Sie den CARU-Support",
        jsonParse: "Server konnte Ihr JSON nicht parsen. Bitte kontrollieren Sie die Daten.",
        templateStored: "Konfigurationsvorlage wurde erfolgreich gespeichert.",
        templateStoredFailure:
          "Ein Fehler ist aufgetreten. Bitte versuchen Sie es nochmals oder kontaktieren Sie den Support.",
        userInvited: "Benutzer wurde erfolgreich eingeladen!",
        userInvitedDescription: "Benutzer wurde eingeladen und die E-Mail mit dem temporären Passwort versendet.",
        userInvitedError:
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es nochmals oder kontaktieren Sie den Support.",
        userUpdates: "Benutzer wurde erfolgreich geändert!",
        deleteUser: "Benutzer wurde erfolgreich gelöscht!",
      },
      auth: {
        error: "Ein Fehler ist aufgetreten",
        invalidCredentials: "Benutzername oder Passwort falsch.",
      },
    },
    device: {
      label: "Gerät | Geräte",
      selection: "{ selection } von { length } Geräten ausgewählt",
      listPageTitle: "Nutzerverwaltung",
      detailsPageTitle: "Nutzerkonto",
      softwareSupport:
        "Ihr Gerät ist mit einer veralteten Softwareversion ausgestattet. Bitte schalten Sie das Gerät ein, sodass die neueste Software automatisch installiert werden kann. Sobald das Update durchgeführt wurde kann das Gerät in der Plattform wie gewohnt konfiguriert werden. Für weitere Fragen wenden Sie sich gerne an unseren Support:",
      header: {
        deviceConnectivityStatus: "Geräteverbindungsstatus",
        connectivityStatus: "Verbindungsstatus",
        serialNumber: "Seriennummer",
        id: "Geräte ID",
        tenant: "Kundengruppe",
        firmware: "Firmware",
        version: "Version",
        notifications: "Benachrichtigungen",
        copyConfiguration: "Einstellungen kopieren",
        setGroup: "Zugehörigkeit Kundengruppe einrichten",
        tabNotification: "Benachrichtigung bei Stromausfall",
        unknown: "Unbekannt",
        bracelets: "Funksender Seriennummer konfigurieren",
        braceletColumn: "Funksender IDs",
        setOwner: "Gerät einer Kundengruppe zuweisen",
        dailyCheck: "Tagestaste",
        co2Toggle: "CO2-Ampel",
        hydrationReminder: "Trinkerinnerung",
      },
      form: {
        permissions: "Zugriffsrechte",
        roles: {
          none: "Kein Zugriff",
          viewer: "Nur Ansicht",
          admin: "Admin",
        },
        notifications: "Benachrichtigungen",
        alarmDestinations: "Notruf Kontakte / Alarmzentralen",
        bracelets: "Funksender",
        sourceDevice: "Source Device",
        ownerName: "Kundengruppe",
        selectDevice: "- Gerät auswählen -",
      },
      action: {
        diagnose: "Diagnose",
        group: "Gerätegruppe",
        owner: "Gruppenzugehörigkeit",
        alarmConfig: "Notruf Konfiguration",
        notifications: "Benachrichtigungen",
        reset: "Gerät zurücksetzen",
        reboot: "Gerät neu starten",
        shutdown: "Gerät herunterfahren",
        deactivate: "Gerät deaktivieren",
        selfDiagnosis: "Selbsttest",
        copyConfig: "Einstellungen kopieren",
        advancedNetworkScan: "Erweiterter Netzwerkscan",
        basicNetworkScan: "Basis Netzwerkscan",
        changeOwner: "Kundengruppe wechseln",
        applyConfigTemplate: "Konfigurationsvorlage anwenden",
        cancel: "Abbrechen",
        save: "Speichern",
        addBracelets: "Funksender hinzufügen",
      },
      general: {
        firmware: "Firmware Version",
        modem: "Mobilfunkstatus",
        wifi: "WiFi Status",
        battery: "Batteriestatus",
        dailyCheckIn: "Tagestaste",
      },
      network: {
        pageTitle: "Scan der verfügbaren Mobilfunknetze ",
        header: {
          operator: "Netzbetreiber",
          signalQuality: "Signalstärke",
          networkQuality: "Signalqualität",
          qualityScore: "Netzwerkqualität",
          selectNetwork: "Netzwerk auswählen",
        },
        signal: {
          unusable: "Nicht verwendbar",
          bad: "schwach",
          ok: "mittel",
          good: "gut",
        },
        performance: {
          uploadSpeed: "Upload Geschwindigkeit: { uploadSpeed } kbps",
          downloadSpeed: "Download Geschwindigkeit: { downloadSpeed } kbps",
          jitter: "Jitter: { jitter } ms",
          roundTripTime: "Round Trip Time: { roundTripTime } ms",
          packetLoss: "Paketverluste: { packetLoss }",
        },
        metrics: {
          uploadSpeed: "Upload Geschwindigkeit: { uploadSpeed } kbps",
          downloadSpeed: "Download Geschwindigkeit: { downloadSpeed } kbps",
          jitter: "Jitter: { jitter } ms",
          roundTripTime: "Round Trip Time: { roundTripTime } ms",
          packetLoss: "Paketverluste: { packetLoss }",
        },
        scan: {
          startedLabel: "{ type } Netzwerkscan gestartet um { started }",
          inProgress: "(in Bearbeitung)",
          quality: "Qualität: { signalLevel }",
          rsrp: "RSRP: { rsrp }",
          rsrq: "RSRQ: { rsrq }",
          rssi: "RSSI: { rssi }",
        },
      },
      events: {
        pageTitle: "Geräteereignisse",
        header: {
          type: "Veranstaltung",
          timestamp: "Zeitstempel",
        },
        empty: "Keine Veranstaltungen verfügbar",
      },
      feature: {
        header: {
          featureFlags: "Caru Care Funktionen ein-/ausstellen",
          developmentSettings: "Entwicklungseinstellungen",
        },
        audio: "Audio: Lautstärke",
        cortex: "Cortex: Log Level",
      },
      settings: {
        general: "Allgemein",
        alertDestinations: "Notrufeinstellungen",
        bracelets: "Funksender",
        connectivity: "Geräteverbindung",
        events: "Veranstaltungen",
        features: "Zusatzfunktionen",
      },
      tooltip: {
        braceletPresenceStatus: "Funksender Funkstrecken",
        dailyCheckIn: "Tagestaste",
        braceletBatteryStatus: "Funksender Batterie",
        presenceStatus: "Basisstation Cloud Verbindung",
        wifiStatus: "Basisstation WLAN",
        powerSupplyStatus: "Basisstation Stromversorgung",
      },
      placeholder: "Geräte ID suchen",
      loading: "Geräte werden geladen...",
      errorStatus: {
        ok: "OK",
        error: "Fehler",
        unknown: "Unbekannt",
        off: "Ausgeschaltet",
        emptyBattery: "Akku leer",
        missingDevice: "Offline",
        lowPowerMode: "Stromsparmodus",
      },
    },
    notification: {
      deviceReset: "Zurücksetzen des Geräts ist gestartet...",
      deviceDiagnosis: "Geräteselbsttest ist gestartet...",
      deviceRefresh: "Gerät {serialNumber} ist aktualisiert",
      deviceAdvancedScan: "Erweiterter Netzwerkscan ({scanId}) gestartet für Gerät: {deviceId}",
      deviceBasicScan: "Standard Netzwerkscan ({scanId}) gestartet für Gerät: {deviceId}",
      header: {
        plugoutNotifications: "Benachrichtigung bei Stromausfall",
      },
      form: {
        sms: "SMS",
        email: "E-Mail",
      },
      action: {
        addNotificationReceiver: "Empfänger hinzufügen",
      },
    },
    user: {
      listPageTitle: "Nutzerverwaltung",
      detailsPageTitle: "Nutzerkonto",
      header: {
        name: "Name",
        tenant: "Kundengruppe",
        role: "Nutzerrechte",
        edit: "Bearbeiten",
      },
      form: {
        permissions: "Nutzerrechte",
        roles: {
          none: "Kein Zugriff",
          viewer: "Nur Ansicht",
          admin: "Admin",
        },
      },
      placeholder: "Geräte Suchen",
    },
    alert: {
      applyNotificationTitle: "Konfiguration der Benachrichtigungen speichern ?",
      applyAlarmTitle: "Notrufkonfiguration speichern ?",
      applyGroupTitle: "Gruppen Zugehörigkeit speichern ?",
      applyOwnerTitle: "Kundengruppen Zugehörigkeit speichern ?",
      resetDeviceTitle: "Gerät zurücksetzen",
      applyBatchResetTitle: "Geräte zurücksetzen",
      applyBatchRebootTitle: "Geräte neu starten",
      applyBatchShutdownTitle: "Geräte herunterfahren",
      applyBatchDeactivateTitle: "Geräte deaktivieren",
      applyChangesTitle: "Änderungen bestätigen",
      leavePage: "Seite verlassen",
      applyBatchResetDescription:
        "Möchten Sie die ${length} Geräte wirklich zurücksetzen? Diese Aktion kann nicht Rückgängig gemacht werden.",
      applyBatchRebootDescription: "Möchten Sie die ${length} Geräte wirklich neu starten?",
      applyBatchShutdownDescription: "Möchten Sie die ${length} Geräte wirklich herunterfahren?",
      applyBatchDeactivateDescription: "Möchten Sie die ${length} Geräte wirklich deaktivieren?",
      applyNotificationDescription:
        "Möchten Sie diese Benachrichtigungs Einstellungen auf alle Geräte anwenden? Die bestehenden Einstellungen werden dabei überschrieben. Diese Aktion kann nicht rückgängig gemacht werden.",
      applyAlarmDescription:
        "Möchten Sie diese Notruf Einstellungen auf alle Geräte anwenden? Die bestehenden Notruf Einstellungen werden dabei überschrieben. Diese Aktion kann nicht rückgängig gemacht werden.",
      applyGroupDescription:
        "Möchten Sie den Gruppenname auf alle Geräte anwenden? Der bestehenden Gruppenname wird dabei überschrieben. Diese Aktion kann nicht rückgängig gemacht werden",
      applyOwnerDescription:
        "Möchten Sie diese Einstellung auf alle Geräte anwenden? Die bestehende Einstellung wird dabei überschrieben. Diese Aktion kann nicht rückgängig gemacht werden.",
      resetDeviceDescription:
        "Möchten Sie dieses Gerät wirklich zurücksetzen? Diese Aktion kann nicht rückgängig gemacht werden.",
      applyChangesDescription: "Möchten Sie die Änderungen wirklich übernehmen?",
      leavePageDescription: "Sie haben ungesicherte Änderungen. Sind Sie sicher, dass Sie die Seite verlassen wollen?",
      deleteTemplateTitle: "Vorlage Löschen?",
      deleteTemplateDescription: "Sind Sie sicher, dass die Vorlage gelöscht werden soll?",
    },
    configuration: {
      header: {
        config: "Einstellungen",
      },
      expertMode: "Expert Mode",
      editJson: "Edit Json",
    },
    safety: {
      header: {
        safety: "Sicherheit",
      },
    },
    health: {
      header: {
        health: "Gesundheit",
      },
    },
    system: {
      header: {
        system: "System",
        volumeSettings: "Gerätelautstärke",
      },
      volume: {
        quiet: "leise",
        default: "normal",
        loud: "laut",
      },
    },
    scaip: {
      form: {
        header: "Geräte ID Einstellungen",
        controllerId: "Geräte ID",
        callerId: "Anrufer ID",
        errorControllerId: "Kontroller ID darf nur aus Großbuchstaben und Zahlen bestehen",
      },
    },
    userManagement: {
      listPageTitle: "Benutzerverwaltung",
      tab: {
        user: "Benutzer",
        tenants: "Organisation",
        inviteUser: "Benutzer einladen",
        editUser: "Benutzer bearbeiten",
        createTenant: "Tenant erstellen",
        updateTenant: "Tenant bearbeiten",
      },
      userTable: {
        name: "Name",
        email: "E-Mail",
        phone: "Telefonnummer",
        tenants: "Organisation",
        role: "Rolle",
        state: "Status",
      },
      tenantTable: {
        name: "Name",
        slug: "Slug",
        devices: "Geräte",
        serialNumber: "Seriennummer",
        users: "Benutzer",
        deviceTenants: "Geräte der Organisation",
        newDevices: "Neue Geräte",
      },
      action: {
        inviteUser: "Benutzer Einladen",
        inviteUserDescription:
          "Bitte bestätigen Sie, dass Sie den Benutzer hinzufügen möchten. Eine E-Mail mit einem temporären Passwort wird an den neuen Benutzer gesendet.",
        deleteUser: "Benutzer Löschen",
        deleteUserDescription: "Sind Sie sicher, dass der Benutzer {email} gelöscht werden soll?",
        updateUser: "Benutzer Ändern",
        updateUserDescription: "Sind Sie sicher, dass der Benutzer geändert werden soll?",
        createTenant: "Organisation erstellen",
        createTenantDescription: "Sind Sie sicher, dass Sie die Organisation erstellen wollen?",
        updateTenant: "Organisation bearbeiten ",
        updateTenantDescription: "Sind Sie sicher, dass Sie die Änderungen speichern wollen?",
      },
    },
    configTemplates: {
      listPageTitle: "Konfigurationsvorlagen",
      listPageTitleEdit: "Konfigurationsvorlage",
      header: {
        id: "Id",
        name: "Name",
        placeholder: "Platzhalter",
        lastUpdate: "Letztes Update",
        lastUpdateBy: "Letzte Änderung",
        owner: "Besitzer",
        applier: "Anwender",
        edit: "Bearbeiten",
        template: "Template",
        preview: "Vorschau",
        example: "Beispielwert",
      },
      templateDescription: "Platzhalter starten und enden mit '$'.",
    },
    alarm: {
      listPageTitle: "Alarmzentralen",
      header: {
        name: "Name",
        tenant: "Kundengruppe",
        alertTriggers: "Notrufauslösemöglichkeiten konfigurieren",
        alarmDestinations: "Notrufempfänger",
      },
      destination: {
        pstn: "PSTN",
        sip: "SIP",
        arc: "Notrufzentrale",
        isArc: "Ist Notrufzentrale",
        arcName: " ARC-Name (SCAIP-Server-Address)",
      },
      form: {
        name: "Name",
        host: "Host",
        port: "Port",
        username: "Nutzername",
        password: "Passwort",
        tenant: "Kundengruppe",
        country: {
          caru: "CARU",
          caritasSoest: "Caritas Soest",
        },
        selectArc: "-- Notruf Zentrale auswählen --",
        triggerByVoice: "Notrufauslösung über die Stimme (Hilfe, Hilfe)",
        triggerByTouch: "Notrufauslösung durch Berühren des Deckels am Gerät",
        triggerByBracelet: "Notrufauslösung durch Sturzerkennung",
        triggerByVoiceOnBattery: "Im Batterie Modus aktiv",
        triggerByVoiceOnBatteryActive: "Die Batterie hält ca. 2h",
        triggerByVoiceOnBatteryInactive: "Die Batterie hält ca. 24h",
      },
      action: {
        addDestination: "Notrufkontakt hinzufügen",
      },
      tooltip: {
        triggerByVoiceOnBatteryActive:
          "Die Notrufauslösung über die Stimme wird während eines Stromunterbruchs deaktiviert, um den Batterie-Verbrauch zu minimieren und die Zeit zur Überbrückung des Unterbruches zu maximieren. Während eines Stromunterbruches kann kein Notruf über die Stimme ausgelöst werden. Notrufe können weiterhin über den Funksender und am CARU care ausgelöst werden.",
        triggerByVoiceOnBatteryInactive:
          "Die Notrufauslösung über die Stimme ist auch während eines Stromunterbruches möglich. Durch den Stromverbrauch der Spracherkennung ist die Zeit zur Überbrückung des Unterbruches beschränkt.",
      },
    },
    dailyCheck: {
      status: {
        ok: "OK",
        disabled: "Deaktiviert",
        missing: "Nicht bestätigt",
        suspended: "Pausiert",
        unknown: "Unbekannt",
      },
      form: {
        active: "Aktiv",
        inactive: "Inaktiv",
        suspended: "Pausiert",
        suspendedDate: "Pausiert bis",
        checkTime: "Startzeit (hh:mm)",
        gracePeriod: "Bestätigungsfenster (min)",
        remindersDescription: "Erinnerung wird nach {firstReminder} und {secondReminder} Minuten abgespielt",
      },
    },
    hydrationReminder: {
      titleDescription: "Die Klänge werden um folgenden Zeiten abgespielt: {animationSlot} ",
      reminderInterval: "Wiedergabe-Intervall einstellen",
      intervalSelection: "Wiedergabe-Zeitfenster einstellen",
      intervalDescription: "Erinnerungen werden nur in den festgelegten Zeitfenstern abgespielt.",
      intervalPreviewDescription: "Reminders are played around:",
      previewSound: "Vorschau des Klangs",
      previewSoundDescription: "Klang wird nur im Browser abgespielt",
      play: "Abspielen",
      updateVersion:
        "Diese Funktionalität ist ab der Version 2023.3.3 verfügbar. Bitte kontaktieren Sie unseren Support, um diese Version auf Ihrem Gerät zu installieren.",
    },
    events: {
      header: {
        events: "Letzte Geräte Meldungen",
      },
    },
    stateSummary: {
      label: {
        deviceReportedStates: "Geräte Meldungen",
        cloudReportedStates: "Cloud Meldungen",
        powerSupplyStates: "Batteriebetrieb",
        braceletPresenceStates: "Funksender Meldungen",
        braceletBatteryStates: "Funksender Batteriestand",
        deviceReportedState: "Basisstation Geräte Verbindung",
        cloudReportedState: "Basisstation Cloud Verbindung",
        powerSupplyState: "Stromversorgung",
        braceletPresenceState: "Funksender",
        braceletBatteryState: "Funksender Batterie",
        dailyCheckIn: "Tagestaste",
        missingDeviceDueBattery: "Basisstation offline (Batterie leer)",
        missingDeviceDueUnknown: "Basisstation offline (anderer Grund)",
        interruptedPower: "Basisstation in Batteriebetrieb",
        missingBracelets: "Funksender Heartbeat fehlt",
        braceletBattery: "Funksender Batteriestand kritisch",
        wifiConnection: "Wifi Verbindung",
      },
      deviceReportedPresence: {
        online: "Online",
        offlineBatteryEmpty: "Offline leerer Akku",
        offlineLowPowerMode: "Offline Wenig Batterie",
        offlineTurnedOff: "Offline Ausgeschalten",
        unknown: "Unbekannt",
      },
      cloudReportedPresence: {
        online: "Online",
        offlineUnknown: "Offline Unbekannt",
        offlineDeviceInitiated: "Offline Gerät initiiert",
        offlineCloudInitiated: "Offline Cloud initiiert",
        unknown: "Unbekannt",
      },
      powerSupply: {
        connected: "Verbunden",
        disconnected: "Unterbrochen",
        unknown: "Unbekannt",
      },
      braceletPresence: {
        inRange: "In Reichweite",
        outOfRange: "Außer Reichweite",
        unknown: "Unbekannt",
      },
      braceletBattery: {
        ok: "Ok",
        critical: "Kritisch",
        unknown: "Unbekannt",
      },
      dailyCheckIn: {
        active: "Aktiv",
        deactivated: "Deaktiviert",
        pausedByDevice: "Pausiert durch den Benutzer",
        pausedByPlatform: "Pausiert durch die  Plattform",
        missing: "Fehlt",
        unknown: "Unbekannt",
      },
      notifications: {
        maxAmountTitle: "Maximum Filter",
        maxAmount:
          "Es können maximal drei Filter verwendet werden. Bitte löschen Sie einen um einen neuen hinzuzufügen",
      },
    },
    error: {
      filter: {
        label: {
          deviceReportedStates: "Geräte Meldungen",
          cloudReportedStates: "Cloud Meldungen",
          powerSupply: "Batteriebetrieb",
          braceletStates: "Funksender Meldungen",
          braceletBatteryState: "Funksender Batteriestand",
          dailyCheckIn: "Tagestaste",
          missingDeviceDueBattery: "Basisstation offline (Batterie leer)",
          missingDeviceDueUnknown: "Basisstation offline (anderer Grund)",
          interruptedPower: "Basisstation in Batteriebetrieb",
          missingBracelets: "Funksender Heartbeat fehlt",
          braceletBattery: "Funksender Batteriestand kritisch",
        },
        state: {
          online: "Online",
          offline: "Offline",
          offlineLowPower: "Offline Wenig Batterie",
          offlineTurnedOff: "Offline Ausgeschalten",
          offlineDeviceInitiated: "Offline Gerät initiiert",
          offlineCloudInitiated: "Offline Cloud initiiert",
          connected: "Verbunden",
          disconnected: "Unterbrochen",
          inRange: "In Reichweite",
          outOfRange: "Außer Reichweite",
          ok: "Ok",
          critical: "Kritisch",
          deactivated: "Deaktiviert",
          active: "Aktive",
          pausedByCloud: "Pausiert von Cloud",
          pausedByUser: "Pausiert von User",
          missing: "Fehlt",
          missingBracelets: "Funksender Heartbeat fehlt ",
          braceletBattery: "Funksender Batteriestand kritisch",
        },
        actionLabel: {
          missingDeviceDueBattery: "Liste anzeigen",
          missingDeviceDueUnknown: "Liste anzeigen",
          interruptedPower: "Liste anzeigen",
          missingBracelets: "Liste anzeigen",
          braceletBattery: "Liste anzeigen",
        },
      },
      unknown: "Unbekannter Fehler",
      notFound: "nicht gefunden",
      destinationEmpty: "Der Alarmempfänger darf nicht leer sein",
      receiverEmpty: "Der Alarmempfänger darf nicht leer sein",
      braceletEmpty: "Das Funksendernummern-Feld darf nicht leer sein",
      braceletFormat:
        "Die Funksendernummern ist nicht gültig: Sie muss ein 12-Stellige Nummer sein. Beispiel: 006569866354",
      braceletDuplicate: "Funksendernummern müssen eindeutig sein. Eingetragene Werte sind Duplikate.",
      sipAddressFormat: "Keine korrektes SIP Adressformat. Bitte folgendes Format verwenden: nutzer@host.com",
      phoneNumberFormat: "Kein gültiges Rufnummernformat. Bitte wie folgt eingeben: 004178021212",
      emailFormat: "Die E-Mail-Adresse ist nicht gültig. Erwartetes Format: user@host.com",
      empty: "Das Rufnummerfeld darf nicht leer sein",
    },
    search: {
      placeholder: "Geräte suchen",
    },
    profileMenu: {
      signOut: "Abmelden",
    },
    filters: {
      all: "Alle",
      operationStatus: "Betriebsstatus",
      allOk: "Alles OK",
      offline: "Offline",
      deviceNotifications: "Meldung Basisstation",
      batteryPowered: "Batteriebetrieb",
      batteryEmpty: "Batterie leer",
      braceletNotifications: "Meldung Funksender",
      batteryLow: "Batterie schwach",
      braceletOutOfRange: "Ausser Reichweite",
      off: "Ausgeschaltet",
      dailyCheckIn: "Tagestaste",
      dailyCheckInActive: "Aktiv",
      dailyCheckInMissing: "Tagestaste nicht bestätigt",
      dailyCheckInPaused: "Pausiert",
      dailyCheckInByUser: "Durch den Benutzer",
      dailyCheckInPlatform: "Durch die Plattform",
      dailyCheckInDeactivated: "Inaktiv",
      healthNotifications: "Gesundheitsleistungen",
      healthNotificationsCo2: "CO2 Ampel",
      healthNotificationsDrink: "Trinkerinnerung",
      bracelet: "Funksender",
      error: "Fehler",
    },
    alarms: {
      sidebar: {
        critical:
          "Die Dringlichkeit ‘Kritisch’ zeigt an, dass ein schwerwiegendes Problem aufgetreten ist. Eine sofortige Abhilfemaßnahme ist erforderlich.",
        major:
          "Die Dringlichkeit ‘Erheblich’ zeigt an, dass sich ein Problem aufgetreten ist, welches erste Funktionen beeinträchtigt. Eine zeitnahe Abhilfemaßnahme ist erforderlich.",
        minor:
          "Die Dringlichkeit ‘Gering’ zeigt an, dass ein Problem erkannt wurde, dieses jedoch noch nicht funktionsbeeintrechtigend ist.Es sollten Maßnahmen ergriffen werden, um zu verhindern, dass es sich zu seinem schwerwiegenderen Problem entwickelt.",
        warning:
          "Die Dringlichkeit ‘Warnung’ zeigt an, dass ein mögliches Problem erkannt wurde, bevor nennenswerte Auswirkungen zu spüren sind. Es sollten Maßnahmen ergriffen werden, um es besser zu verstehen und zu verhindern, dass es schlimmer wird.",
      },
      network: {
        network_cloud_reported: {
          not_applicable: "Gerät wurde heruntergefahren",
          unknown: "Verbindungsstatus unbekannt",
          normal: "OK: Gerät mit der Cloud verbunden",
          warning: "WARNUNG: Cloud-Verbindung unterbrochen (< 15 min)",
          major: "ERHEBLICH: Cloud-Verbindung unterbrochen (< 1h)",
          critical: "KRITISCH: Cloud-Verbindung unterbrochen (> 1h)",
        },
        modem_link: {
          not_applicable: "Modem-Verbindungsstatus unbekannt",
          unknown: "Modem-Verbindungsstatus unbekannt",
          normal: "OK: Modem mit dem Internet verbunden",
          warning: "WARNUNG: Modem-Verbindung unterbrochen (< 15 min)",
          minor: "GERINGFÜGIG: Modem-Verbindung unterbrochen (> 15 min)",
        },
        modem_connection_quality: {
          not_applicable: "Modem-Signalstärke unbekannt",
          unknown: "Modem-Signalstärke unbekannt",
          normal: "OK: Gute Modem-Signalstärke",
          warning: "WARNUNG: Minimale Modem-Signalstärke",
          minor: "GERINGFÜGIG: Schlechte Modem-Verbindungsqualität",
        },
        network_arc_reported: {
          not_applicable: "Kontrollruf-Status unbekannt",
          unknown: "Kontrollruf-Status unbekannt",
          normal: "OK: Kontrollruf(e) erfolgreich",
          major: "ERHEBLICH: Kontrollruf fehlgeschlagen (>= 3h)",
          critical: "KRITISCH: Kontrollruf fehlgeschlagen (>= 6h)",
        },
      },
      wifi: {
        wifi_link: {
          not_applicable: "WLAN-Verbindungsstatus unbekannt",
          unknown: "WLAN-Verbindungsstatus unbekannt",
          normal: "OK: WLAN mit dem Internet verbunden",
          warning: "WARNUNG: WLAN-Verbindung unterbrochen (< 15 min)",
          minor: "GERINGFÜGIG: WLAN-Verbindung unterbrochen (> 15 min)",
        },
        wifi_connection_quality: {
          not_applicable: "WLAN-Signalstärke unbekannt",
          unknown: "WLAN-Signalstärke unbekannt",
          normal: "OK: Gute WLAN-Signalstärke",
          warning: "WARNUNG: Minimale WLAN-Signalstärke",
          minor: "GERINGFÜGIG: Schlechte WLAN-Verbindungsqualität",
        },
      },
      power: {
        power_supply: {
          not_applicable: "Stromversorgungsstatus unbekannt",
          unknown: "Stromversorgungsstatus unbekannt",
          normal: "OK: Mit Strom verbunden",
          warning: "WARNUNG: Stromversorgung unterbrochen (< 15 min)",
          major: "ERHEBLICH: Stromversorgung unterbrochen (> 15 min)",
        },
        battery_fault: {
          not_applicable: "Batteriezustand der Basisstation unbekannt",
          unknown: "Batteriezustand der Basisstation unbekannt",
          normal: "OK: Batteriezustand der Basisstation normal",
          minor: "GERINGFÜGIG: Basisstation meldet Batteriefehler",
        },
      },
      accessories: {
        bracelet_presence: {
          not_applicable: "Funkstrecken-Status unbekannt",
          unknown: "Funkstrecken-Status unbekannt",
          normal: "OK: Funkstreckentest erfolgreich",
          warning: "WARNUNG: Funkstreckentest fehlt (> 1 Tag)",
          minor: "GERINGFÜGIG: Funkstreckentest fehlt (> 2 Tage)",
          major: "ERHEBLICH: Funkstreckentest fehlt (>= 3 Tage)",
        },
        bracelet_battery: {
          not_applicable: "Funksender-Batteriezustand unbekannt",
          unknown: "Funksender-Batteriezustand unbekannt",
          normal: "OK: Funksender-Batteriezustand normal",
          minor: "GERINGFÜGIG: Funksender-Batterie muss ersetzt werden",
          major: "ERHEBLICH: Funksender-Batterie dringend ersetzen!",
        },
      },
      title: "Technische Alarme",
      titlePower: "Stromversorgung",
      titleNetwork: "Verbindung",
      titleWifi: "Wifi",
      titleAccessories: "Funksender",
      power_supply: "Stromversorgung",
      battery_fault: "Batteriezustand",
      wifi_link: "Wifi-Verbindung",
      wifi_connection_quality: "Wifi-Qualität",
      modem_link: "Modem-Verbindung",
      modem_connection_quality: "Modem-Verbindungsqualität",
      bracelet_presence: "Funksenderpräsenz",
      bracelet_battery: "Funksenderbatterie",
      cloud_connection: "Cloud-Verbindung",
      mqtt_connection: "MQTT-Verbindung",
      network_cloud_reported: "Cloud-Verbindung",
      network_arc_reported: "Zentralen-Kontrollruf",
      unknown: "Unbekannt",
      normal: "Normal",
      warning: "Warnung",
      minor: "Geringfügig",
      major: "Erheblich",
      critical: "Kritisch",
      wifiNotConnected: "Wifi - Nicht verbunden",
      wifiConnected: "Verbunden",
    },
    navigationBar: {
      userManagement: "Benutzerverwaltung",
      configTemplates: "Konfigurationsvorlagen",
      alarmCenters: "Alarmzentralen",
      users: "Benutzer",
      docs: "CARU Academy",
    },
    common: {
      label: {
        useSetting: "Einstellungen übernehmen",
        since: "seit",
      },
      action: {
        cancel: "Abbrechen",
        save: "Speichern",
        edit: "Bearbeiten",
        apply: "Anwenden",
        leave: "Verlassen",
        delete: "Löschen",
      },
    },
    "crn::cortex.lifecycle.battery::event-type:critical": "Batteriestand der Basisstation kritisch",
    "crn::cortex.lifecycle.bracelet::event-type:battery_critical": "Batteriestand des Funksenders niedrig",
    "crn::cortex.lifecycle.bracelet::event-type:heartbeat": "Funksender Heartbeat",
    "crn::cortex.lifecycle.bracelet::event-type:out_of_range": "Keine Verbindung zum Funksender",
    "crn::cortex.lifecycle.system::event-type:booted": "Geräte-Software gestartet",
    "crn::cortex.lifecycle.system::event-type:bottom_button": "Basisstation durch Nutzer ausgeschaltet",
    "crn::cortex.lifecycle.system::event-type:shutdown": "Herunterfahren der Basisstation",
    "crn::cortex.lifecycle.system::event-type:reboot": "Geräte-Software wird neu gestartet",
    "crn::cortex.lifecycle.wall_adapter::event-type:connected": "Stromverbindung zur Basisstation wiederhergestellt",
    "crn::cortex.lifecycle.wall_adapter::event-type:disconnected": "Stromverbindung der Basisstation unterbrochen",
    "crn::cortex.lifecycle::event-type:arc_call": "Notruf initiiert",
    "crn::cortex.lifecycle::event-type:arc_call_v2": "Notruf initiiert",
    "crn::cortex.lifecycle.daily_check_in::event-type:activated": "Tagestaste eingeschaltet",
    "crn::cortex.lifecycle.daily_check_in::event-type:deactivated": "Tagestaste ausgeschaltet",
    "crn::cortex.lifecycle.daily_check_in::event-type:requested": "Tagestaste aktiv (Gerät pulsiert blau)",
    "crn::cortex.lifecycle.daily_check_in::event-type:reminded": "Tagestasten-Erinnerung abgespielt",
    "crn::cortex.lifecycle.daily_check_in::event-type:done": "Tagestaste von Nutzer betätigt",
    "crn::cortex.lifecycle.daily_check_in::event-type:missing": "Bestätigung der Tagestaste durch Nutzer fehlt",
    "crn::cortex.lifecycle.daily_check_in::event-type:suspended_by_platform": "Tagestaste in der Plattform pausiert",
    "crn::cortex.lifecycle.daily_check_in::event-type:resumed_by_platform":
      "Pausierung der Tagestaste beendet (auf Plattform)",
    "crn::cortex.lifecycle.daily_check_in::event-type:suspended_by_device": "Tagestaste durch Nutzer pausiert",
    "crn::cortex.lifecycle.daily_check_in::event-type:resumed_by_device":
      "Pausierung der Tagestaste beendet (am Gerät)",
    "crn::cortex.lifecycle.battery::event-type:fault": "Geräte-Batterie Fehler",
  },
};
