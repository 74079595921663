<template>
  <div :style="indent">
    <div @click="toggleChildren" :class="['flex', 'justify-between', 'px-2', 'py-1', 'text-sm font-medium', 'rounded-md', 'cursor-pointer', 'group', '0',
      ...(searchQuery.includes(query)
        ? ['bg-gray-900', 'text-white']
        : ['text-gray-300', 'hover:bg-gray-700', 'hover:text-white'])]" v-if="amount === undefined">
      <div class="flex my-auto align-middle">
        <component :is="icon" :class="['mr-2', 'flex-shrink-0', 'h-4', 'w-4', 'm-auto']" aria-hidden="true" />
        {{ label }}
      </div>
      <ChevronDownIcon :class="['justify-end', 'flex-shrink-0', 'h-4', 'w-4']" v-if="showChildren" />
      <ChevronUpIcon :class="['justify-end', 'flex-shrink-0', 'h-4', 'w-4']" v-if="!showChildren" />
    </div>

    <div v-else :class="[
      'flex justify-between px-2 py-1 text-sm font-medium rounded-md group cursor-pointer',
      ...(searchQuery.includes(query)
        ? ['bg-gray-900', 'text-white']
        : ['text-gray-300', 'hover:bg-gray-700', 'hover:text-white']),
    ]" @click="filterClicked(query)">

      <span class="flex">
        <span ref="tooltipTrigger" v-if="icon" class="w-5 h-5 mr-2" v-html="getIcon(icon)"></span> {{ label }}

        <div v-if="icon" ref="tooltipTarget" class="max-w-[250px] inline-block absolute invisible z-10 py-2.5 px-3 text-sm font-medium text-black bg-gray-200 shadow-md rounded-lg opacity-0 transition-opacity duration-300 tooltip">
          {{ $t("alarms.sidebar." + icon) }}
          <div class="tooltip-arrow" data-popper-arrow></div>
        </div>
      </span>
      <span v-if="amount || amount == 0" :class="navigationTextColor(color)"> ({{ amount }})</span>
    </div>
  </div>

  <template v-if="showChildren">
    <NavigationTree v-for="node in nodes" v-bind:key="node.name" :nodes="node.subItems" :label="node.name" :depth="depth + 1" :hash="node.hash" :icon="node.icon" :color="node.color" :amount="node.amount" :query="node.query" :searchQuery="searchQuery">
    </NavigationTree>
  </template>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { getIcon, getSidebarTooltip } from "@/lib/alarms";
import { Tooltip } from "flowbite";
import { onMounted, ref } from "vue";


export default {
  name: "NavigationTree",

  components: {
    ChevronDownIcon,
    ChevronUpIcon,
  },

  props: ["label", "nodes", "depth", "hash", "icon", "amount", "color", "query", "searchQuery"],
  data() {
    return { showChildren: true };
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();

    const tooltipTrigger = ref(null);
    const tooltipTarget = ref(null);

    onMounted(() => {
      const options = {
        placement: 'bottom',
        triggerType: 'hover',
      }
      new Tooltip(tooltipTarget.value, tooltipTrigger.value, options);

    })

    return { store, t, getIcon, getSidebarTooltip, tooltipTrigger, tooltipTarget };
  },
  computed: {
    indent() {
      if (this.depth > 1) return { margin: `0 0 0 ${this.depth * 20}px` };

      return { margin: `0 0 0 ${this.depth * 25}px` };
    },
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    navigationTextColor(color) {
      if (color == "green") {
        return "text-green-300";
      } else if (color == "orange") {
        return "text-orange-300";
      } else if (color == "red") {
        return "text-red-300";
      } else if (color == "yellow") {
        return "text-yellow-100";
      } else {
        return "text-gray-300";
      }
    },

    filterClicked(filter) {
      this.$store.dispatch("addFilterToSearch", filter);
    },
  },
};
</script>
